import React from 'react';

function VerificationInput({ verificationCode, onChange, error }) {
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
      // Move to previous input on backspace if current input is empty
      const prevInput = document.getElementById(`code-${index - 1}`);
      prevInput?.focus();
      onChange(index - 1, '');
    }
  };

  const handlePaste = (e, startIndex) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').replace(/\D/g, '');
    const digits = pastedData.split('').slice(0, 6 - startIndex);

    digits.forEach((digit, index) => {
      const targetIndex = startIndex + index;
      if (targetIndex < 6) {
        onChange(targetIndex, digit);
        // Focus next input after paste
        if (targetIndex < 5 && digits[index + 1]) {
          const nextInput = document.getElementById(`code-${targetIndex + 1}`);
          nextInput?.focus();
        }
      }
    });
  };
  return (
    <div className="flex justify-center gap-2 mb-8">
      {verificationCode.map((digit, index) => (
        <input
          key={index}
          id={`code-${index}`}
          type="tel"
          maxLength={1}
          inputMode="numeric"
          value={digit}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={(e) => handlePaste(e, index)}
          onChange={(e) => onChange(index, e.target.value)}
          className={`w-12 h-12 border-2 rounded-lg text-center text-xl font-semibold 
          ${error
              ? 'border-red-500 text-red-600 bg-red-50'
              : 'text-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200'
            } outline-none transition-all`} />
      ))}
    </div>
  );
}

export default VerificationInput;
