/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-useless-concat */
/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { MdDownload, MdEmail, MdError, MdDelete, MdTranslate } from 'react-icons/md';
import { VscEye, VscRunErrors } from 'react-icons/vsc';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { v4 as uuidv4 } from 'uuid';
import './MessageBubble.css';
import {
  AiFillFilePdf,
} from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa';
import MouseOverPopover from './components/MouseOverPopover';

function ChatMessage({ message }) {
  const [showDownloadLink, setShowDownloadLink] = useState(false);
  const handleVideoError = () => {
    setShowDownloadLink(true);
  };
  const isFbChat = false;
  const handleDownloadClick = (linkTa) => {
    navigator.clipboard.writeText(linkTa);
    toast.success('Media URL copied to clipboard!');
  };
  const splitMsg = (str) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = str?.split(urlRegex);

    return parts?.map((part, index) => {
      if (urlRegex.test(part)) {
        return (
          <a
            className={message?.is_me === true
              ? 'leading-7 h-fit underline text-blue-600 hover:text-blue-700'
              : `leading-7 underline ${message.message_type === 'email_message'
                ? 'text-blue-600 hover:text-blue-700' : ''}`}
            key={index}
            href={(part?.length > 0 && part[part.length - 1] === '.') ? part?.substring(0, part.length - 1) : part}
            target="_blank"
            rel="noreferrer"
          >
            {part}
          </a>
        );
      } else if (part.toString().length) {
        return (
          <span key={index}>
            {part.split(/\r\n|\r|\n/).map((line, lineIndex) => (
              <React.Fragment key={`line-${lineIndex}`}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </span>
        );
      } else {
        return '';
      }
    });
  };

  const aUniqueUUID = `aaa-zzz-${message[0] ?? ''}`;

  const audioTypes = [
    'audio/basic',
    'audio/L24',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/vnd.rn-realaudio',
    'audio/vnd.wave',
    'audio/3gpp',
    'audio/3gpp2',
    'audio/ac3',
    'audio/webm',
    'audio/amr-nb',
    'audio/amr',
  ];

  const imageTypes = [
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/png',
    'image/tiff',
    'image/bmp',
  ];

  const videoTypes = [
    'video/mpeg',
    'video/mp4',
    'video/quicktime',
    'video/webm',
    'video/3gpp',
    'video/3gpp2',
    'video/3gpp-tt',
    'video/H261',
    'video/H263',
    'video/H263-1998',
    'video/H263-2000',
    'video/H264',
  ];
  return (

    <div className="flex mt-[10px] inbox-message-regular" style={{ justifyContent: message?.is_me === true ? 'flex-start' : 'flex-end' }}>
      <div className={message?.is_me === true ? 'flex flex-col space-y-2 items-start inbox-message-regular' : 'flex flex-col space-y-2 items-end inbox-message-regular'}>
        <div className={message?.is_me === true ? 'flex flex-row-reverse ml-2 inbox-message-regular' : 'flex  space-x-2 inbox-message-regular'}>
          <div className={message?.is_me === true ? 'space-y-1 flex flex-col items-start' : 'space-y-1 flex flex-col items-end'}>
            <div
              style={{ display: message.body === '' ? 'none' : 'flex' }}
              className={message?.is_me === true
                ? `${message?.message_type === 'direct_feedback' ? 'bg-white border' : 'bg-[#eee]'} ml-2 p-[10px] flex flex-col rounded-t-[20px] inbox-message-regular text-[14px] font-medium rounded-br-[20px] 2xl:max-w-[450px] xl:max-w-[350px] max-w-[200px] break-words`
                : 'bg-[#3975cb] p-[10px] flex flex-col text-white rounded-t-[20px] inbox-message-regular text-[14px]  rounded-bl-[20px] 2xl:max-w-[450px] xl:max-w-[350px] max-w-[200px] break-words'}
            >
              <span className="self-start w-full  px-1 inbox-message-regular">
                {splitMsg((message?.body ?? message?.message_body)?.toString().trim())}
              </span>

              {message?.link?.length > 0 && (
                <a href={message.link} className="px-1 !underline cursor-pointer hover:text-blue-600">{message.link}</a>
              )}
            </div>

            <div>
              {' '}
              <div className={message?.is_me === true ? 'flex flex-col  space-y-1 ml-2 justify-start' : ' flex flex-col self-end object-fill justify-end '}>
                {(message?.media) && (
                  typeof message?.media === 'string' ? message?.media?.split(',')?.map((e) => (
                    (
                      imageTypes.find((str) => str === e.toString().substring(e.toString().indexOf('~') + 1))
                      || e.indexOf('~') === -1
                    ) ? (
                      <>
                        <PhotoView key={crypto.randomUUID()} src={e.indexOf('~') === -1 ? e : e.toString().substring(0, e.toString().indexOf('~'))}>
                          <img
                            className={message?.is_me === true ? '2xl:max-w-[250px]  xl:max-w-[150px] md:max-h-[200px] max-h-[150px] object-fill rounded-t-[20px] rounded-br-[20px] mb-1  cursor-pointer' : '2xl:max-w-[250px]  xl:max-w-[150px] md:max-h-[200px] max-h-[150px] object-fill self-end rounded-t-[20px] rounded-bl-[20px] mb-1  cursor-pointer'}
                            src={e.indexOf('~') === -1 ? e : e.toString().substring(0, e.toString().indexOf('~'))}
                            key={uuidv4()}
                            alt="media"
                          />
                        </PhotoView>
                      </>
                    ) : (
                      audioTypes.find((str) => str === e.toString().substring(e.toString().indexOf('~') + 1))
                        ? (
                          <audio key={uuidv4()} controls src={e.toString().substring(0, e.toString().indexOf('~'))} />
                        ) : (
                          (
                            videoTypes.find((str) => str === e.toString().substring(e.toString().indexOf('~') + 1))
                          ) ? (
                            showDownloadLink ? (
                              <div
                                className={message?.is_me === true ? '2xl:max-w-[450px] mt-[1rem] pl-[1rem] min-w-[200px]  xl:max-w-[350px] max-h-[400px] object-fill rounded-t-[20px] rounded-br-[20px] mb-1 flex flex-col items-center justify-center' : '2xl:max-w-[450px]  xl:max-w-[350px] flex flex-col max-h-[400px] object-fill self-end rounded-t-[20px] rounded-bl-[20px] mb-1 min-w-[200px]  items-center justify-center  mt-[1rem] pl-[1rem]'}
                              >
                                <VscRunErrors size={50} className="mb-[0.5rem] text-slate-600" />
                                <button
                                  type="button"
                                  onClick={() => handleDownloadClick(e.toString().substring(0, e.toString().indexOf('~')))}
                                >
                                  <span className="text-blue-500 text-sm cursor-pointer">
                                    Copy Video URL
                                  </span>
                                </button>
                                <span className="text-sm mt-[0.7rem] mb-[0.3rem]">
                                  Media type not supported by your browser
                                </span>
                                <span className="text-sm">
                                  Try pasting video url in any other browser
                                </span>
                              </div>
                            ) : (
                              <video
                                className={message?.is_me === true ? '2xl:max-w-[250px]  xl:max-w-[150px] md:max-h-[200px] max-h-[150px] object-fill rounded-t-[20px] rounded-br-[20px] mb-1  cursor-pointer' : '2xl:max-w-[250px]  xl:max-w-[150px] md:max-h-[200px] max-h-[150px] object-fill self-end rounded-t-[20px] rounded-bl-[20px] mb-1  cursor-pointer'}
                                controls
                                onError={handleVideoError}
                              >
                                <source src={e.toString().substring(0, e.toString().indexOf('~'))} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            )
                            // <video
                            //   key={uuidv4()}
                            //   controls
                            //   src={e.toString().substring(0, e.toString().indexOf('~'))}
                            //   className={message?.is_me === true ? '2xl:max-w-[250px]  xl:max-w-[150px] md:max-h-[200px] max-h-[150px] object-fill rounded-t-[20px] rounded-br-[20px] mb-1  cursor-pointer' : '2xl:max-w-[250px]  xl:max-w-[150px] md:max-h-[200px] max-h-[150px] object-fill self-end rounded-t-[20px] rounded-bl-[20px] mb-1  cursor-pointer'}
                            // />
                          ) : (
                            e.toString().substring(e.toString().indexOf('~') + 1) === 'text/vcard' ? (
                              <div
                                key={uuidv4()}
                                className={message?.is_me === true
                                  ? 'bg-[#eee] ml-2 p-[10px] flex flex-row rounded-t-[20px] items-center inbox-message-regular text-[14px] font-medium rounded-br-[20px] 2xl:max-w-[450px] xl:max-w-[350px] w-max break-words '
                                  : 'bg-[#3975cb] p-[10px] flex flex-row text-white rounded-t-[20px] items-center inbox-message-regular text-[14px]  rounded-bl-[20px] 2xl:max-w-[450px] xl:max-w-[350px] w-max break-words'}
                              >
                                <FaUserCircle className="mr-1 pb-1" size={35} />
                                <div className="flex flex-col items-center justify-center">
                                  Contact File
                                  <div
                                    onClick={() => window.open(e.toString().substring(0, e.toString().indexOf('~')))}
                                    className="text-[11px] cursor-pointer text-blue-500 pr-1"
                                  >
                                    Download
                                  </div>
                                </div>
                              </div>
                            ) : (
                              e.toString().substring(e.toString().indexOf('~') + 1) === 'application/pdf' && (
                                <div
                                  className={message?.is_me === true
                                    ? 'bg-[#eee] ml-2 p-[10px] flex flex-row rounded-t-[20px] items-center inbox-message-regular text-[14px] font-medium rounded-br-[20px] 2xl:max-w-[450px] xl:max-w-[350px] w-max break-words '
                                    : 'bg-[#3975cb] p-[10px] flex flex-row text-white rounded-t-[20px] items-center inbox-message-regular text-[14px]  rounded-bl-[20px] 2xl:max-w-[450px] xl:max-w-[350px] w-max break-words'}
                                >
                                  <AiFillFilePdf className="mr-1 pb-1" size={35} />
                                  <div className="flex flex-col items-center justify-center">
                                    PDF File
                                    <div
                                      onClick={() => window.open(e.toString().substring(0, e.toString().indexOf('~')))}
                                      className="text-[11px] cursor-pointer text-blue-500"
                                    >
                                      Download
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          )
                        )
                    )
                  )) : (
                    <div
                      className={message?.is_me === true ? '2xl:max-w-[250px]  xl:max-w-[150px] md:max-h-[200px] max-h-[150px] object-fill rounded-t-[20px] rounded-br-[20px] mb-1  cursor-pointer' : '2xl:max-w-[250px]  xl:max-w-[150px] md:max-h-[200px] max-h-[150px] object-fill self-end rounded-t-[20px] rounded-bl-[20px] mb-1  cursor-pointer'}
                    >
                      Error, kindly refresh
                    </div>
                  )
                )}
              </div>
              {' '}
            </div>
          </div>
        </div>

        <div className={message?.is_me === true ? 'flex space-x-3 items-center flex-row-reverse' : 'flex space-x-3 items-center'}>
          {message?.message_status && (
            <div className="flex space-x-1 items-center">
              <MouseOverPopover
                mainContent={(
                  <MdError className="text-red-500 mb-[0.2rem] cursor-pointer" size={11} />
                )}
                popoverContent={`${message?.message_status}`}
              />
              {/* <MdError className="text-red-500 mb-[0.2rem]" size={11} /> */}
              <span style={{ fontSize: '11px', color: 'red' }}>
                Undelivered
              </span>
            </div>
          )}
          <div className={message?.is_me === true ? 'flex items-center !ml-2 ' : 'w-full flex items-center justify-end'}>
            <div className="flex items-center space-x-1">
              <div style={{ fontSize: '11px', color: 'gray' }} className="inbox-message-regular">
                {message?.created_at}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatMessage;
