/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Send, Image, Paperclip, LogOut } from 'lucide-react';
import ChatMessage from './ChatMessage';
import ChatInput from './ChtaInput';
import { SocketContext } from './socket';
import { FiLoader } from "react-icons/fi";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { v4 as uuidv4 } from 'uuid';
import MouseOverPopover from './components/MouseOverPopover';

function ChatApp({ companyName, profilePhoto, setChatAppVerified }) {

    const socket = useContext(SocketContext);
    const { compId, cId } = useParams();
    const [isRecording, setIsRecording] = useState(false);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    useEffect(() => {
        handleGetConversation();
    }, []);
    const handleGetConversation = async () => {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/chat/get-all-secure-chat-messages/${cId}`, {
            headers: {
                'Cache-Control': 'no-cache',
                Pragma: 'no-cache',
                Expires: '0',
            }
        })
        if (response?.data?.status === 'success') {
            setMessages(response?.data?.data);
            setLoading(false);
        }
    }
    const messageEndRef = useRef(null);
    useEffect(() => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest'
                })
        }
    }, [messages]);
    return (


        <div className="max-h-[calc100vh] bg-gradient-to-br font-[GraphikRegular] from-blue-50 to-indigo-100 flex items-center justify-center p-4 ">
            <div className="md:w-[50vw] w-[90vw] bg-white rounded-lg shadow-lg flex flex-col md:h-[600px] max-h-[calc(100vh-50px)] overscroll-y-none">
                <div className="bg-gray-900 text-white p-4 flex items-center justify-between rounded-t-lg">
                    <div className="flex items-center gap-2">
                        <img
                            src={profilePhoto}
                            className="flex flex-row shadow-gray-400 shadow rounded-full cursor-pointer h-[30px] w-[30px] object-cover justify-start items-center"
                            alt={companyName}
                        />
                        <h1 className=" font-semibold capitalize">{companyName}</h1>
                    </div>
                    <MouseOverPopover
                        mainContent={(
                            <div className="flex items-center gap-3 p-1 cursor-pointer hover:bg-slate-100 hover:rounded-2xl hover:text-slate-800 ">
                                <LogOut className="w-5 h-5 " onClick={() => { setChatAppVerified(false); localStorage.removeItem('isVerified'); }} />

                            </div>
                        )}
                        popoverContent={`Logout`}
                    />

                </div>

                {loading ? (
                    <div className="flex-1 overflow-y-hidden flex items-center justify-center min-h-[300px]  overflow-x-hidden">
                        <FiLoader size={50} className="animate-spin " />
                    </div>
                ) : (
                    <div className="flex-1 overflow-y-auto p-4 space-y-4  overflow-x-hidden">
                        {messages?.map((msg) => (
                            <ChatMessage key={uuidv4()} message={msg} />

                        ))}
                        <div ref={messageEndRef} />
                    </div>
                )}

                <ChatInput
                    setMessages={setMessages}
                    messages={messages}
                />
            </div>
        </div>
    );
}

export default ChatApp;
