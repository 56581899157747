import * as React from 'react';
import Popover from '@mui/material/Popover';

export default function MouseOverPopover({
  mainContent, popoverContent, showPopOver, type, 
  usedIn,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <div
        sx={{
          '& .MuiButton-outlined': {
            borderColor: '#c4c4c4',
            borderWidth: '1.5px',
          },
          '& .MuiTypography-root': {
            color: 'black',
          },
          '& .MuiButton-outlinedSizeMedium': {
            color: '#555555',
          },
        }}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverClose}
      >
        {mainContent}
      </div>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          // display: showPopOver ? 'block' : 'none',
        }}
        style={{
          marginTop: usedIn ? '-38px' : '22px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        className={type === 'leads' ? showPopOver ? 'block !z-[99999999]' : 'hidden !z-[99999999]' : 'block !z-[99999999]'}
      >
        <div
          style={{
            background: '#192d45',
            borderRadius: '4px',
            color: '#fff',
            padding: '10px',
            fontSize: '13px',
            textAlign: 'left',
          }}
        >
          {popoverContent}
        </div>
      </Popover>
    </>
  );
}
