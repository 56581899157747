import React, { useState, useContext, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { RiCloseCircleFill, RiHistoryLine } from 'react-icons/ri';
import { Send, Image, Paperclip } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import { SocketContext } from './socket';
import { FiLoader } from "react-icons/fi";
import toast from 'react-hot-toast';

const imgConfig = {
  Accept: 'application/json',
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};
// Attachment Buttons Component

const AttachmentButtons = ({ setimages, images, message, setMessage }) => {
  const fileInputRef = useRef(null);
  const [acceptType, setAcceptType] = useState('');
  const [Loading, setLoading] = useState(false);

  const handleFileChange = async (e) => {
    setLoading(true);
    e.preventDefault();
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('selectedFile', file);

    const size = file.size;
    if (size / 1024 < 5000) {
      formData.append('fetch_scap_link', false);
    }
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/resource/upload-v2`,
        formData,
        imgConfig
      );
      if (res?.data?.status === 'success') {
        toast.success(res?.data?.message);
        const fileType = file.type;
        if (fileType.startsWith('image/')) {
          setimages(res?.data?.data?.details);
        } else {
          const fileExtension = file.name.split('.').pop().toUpperCase();
          setMessage(
            `${message} \nI have attached a ${fileExtension} file, use this link to download: ${res?.data?.data?.details}`
          );
        }
      } else {
        toast.error('Failed to upload file!');
      }
    } catch (err) {
      console.error('Error uploading file:', err);
    }
    setLoading(false);
  };

  const handleButtonClick = (type) => {
    setAcceptType(type);
    fileInputRef.current.click();
  };

  if (Loading) {
    return <FiLoader size={50} className="animate-spin w-5 h-5 " />;
  }

  return (
    <div className="flex sm:flex-row flex-col">
      {images === '' && (
        <input
          type="file"
          ref={fileInputRef}
          accept="image/png, image/jpg, image/gif, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      )}

      {images !== '' ? (
        <div className="w-[40px] h-[40px] rounded relative">
          <div
            key={Math.random()}
            className="w-[100%] h-[100%] overflow-hidden cursor-pointer"
          >
            <img
              className="h-[40px] w-[40px] border border-slate-700 rounded-lg"
              src={images}
              style={{ objectFit: 'cover' }}
              alt="media"
            />
          </div>
          <RiCloseCircleFill
            onClick={() => setimages('')}
            className="!absolute !-top-3 !-left-3 cursor-pointer"
            size={20}
          />
        </div>
      ) : (
        <button onClick={() => handleButtonClick('')} className="p-2">
          <Paperclip className="w-5 h-5 hover:text-blue-500" />
        </button>
      )}
    </div>
  );
};



// Chat Input Component
const ChatInput = ({ setMessages, messages }) => {
  const socket = useContext(SocketContext);
  const [message, setMessage] = useState('');
  const [images, setimages] = useState('');
  const { compId, cId } = useParams();
  const handleSendMessage = async () => {
    const body = {
      is_me: false,
      conversation_id: cId,
      secure_message_body: message,
      company_id: compId,
      media_url: images,
    };
    const route = 'secure_chat.send_message';
    socket.emit('secure_chat_service', { route, body });
    setMessage('');
    setimages('');
  };
  useEffect(() => {
    function handleSend(value) {
      let datavalue;
      try {
        datavalue = JSON.parse(JSON.parse(value));
      } catch (error) {
        return;
      }
      if (
        datavalue.route === 'secure_chat.send_message.response' &&
        datavalue.company_id === compId &&
        datavalue.conversation_id === cId
      ) {
        setMessages(messages => [...messages, datavalue.data]);
      } else if (
        datavalue.route === 'secure_chat.send_message.error_response' &&
        datavalue.company_id === compId &&
        datavalue.conversation_id === cId
      ) {
        toast.error(datavalue.message);
      } else {
        // console.warn("Unexpected data received:", datavalue);
      }
    }

    socket.on('secure_chat_service', handleSend);
    return () => {
      socket.off('secure_chat_service', handleSend);
    };
  }, [socket]);
  return (
    <div className="sticky bottom-0 bg-white p-4">
      <div className="flex items-center gap-2">
        <AttachmentButtons
          setimages={setimages}
          images={images}
          setMessage={setMessage}
          message={message}
        />
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="flex-1 resize-none text-sm px-4 py-2 border rounded  focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Type a message..."
        />
        {(message.trim() || images !== '') ? (
          <button onClick={() => { handleSendMessage(); }} className="p-2 bg-blue-400 hover:bg-blue-500 flex items-center justify-center text-white rounded">
            <Send className="w-5 h-5 " />
          </button>
        ) : (
          <div className="p-2 bg-slate-200 flex items-center justify-center text-white rounded">
            <Send className="w-5 h-5 " />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatInput;
